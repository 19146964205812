const {
  REACT_APP_host_url,
  // REACT_APP_api_url="http://localhost:8080/api/v1",
  REACT_APP_api_url="http://3.109.251.83:8082/api/v1",

  REACT_APP_google_api_key,
} = process.env;

module.exports = {
  // urls
  url: REACT_APP_host_url,
  API_URL: REACT_APP_api_url,

  // google map key
  GOOGLE_API_KEY: REACT_APP_google_api_key,
};
