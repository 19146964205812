import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import { CMSSchema } from "../../Helpers/Constants/Schemas";
import { CMSFields } from "../../Helpers/Constants/InitialValues";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import Loader from "../Common/Loader";

const CMS = () => {
  const [initialValues] = useState(CMSFields.required);
  const [data, setData] = useState(initialValues);
  const [visible, setVisible] = useState({ loader: false });
  const [filter, setFilter] = useState({ type: 1 });

  const handleSubmit = async (data) => {
    try {
      await Axios.patch(API.CMS.UPDATE, {
        cmsId: data._id,
        title: data.title,
        description: data.description,
      });
      getData();
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.CMS.DETAILS, filter);
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    getData();
  }, [filter]);

  const FormikForm = () => (
    <Formik
      initialValues={data}
      validationSchema={CMSSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values);
        resetForm();
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className="pt-3">
          <div className="inner-form">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Title</label>
                <Field className="form-control" type="text" name="title" />
                {errors.title && touched.title ? (
                  <div className="error">{errors.title}</div>
                ) : null}
              </div>

              <div className="col-md-9 mb-3">
                <label>Description</label>
                <Field
                  className="form-control h-210"
                  type="text"
                  component="textarea"
                  name="description"
                />
                {errors.description && touched.description ? (
                  <div className="error">{errors.description}</div>
                ) : null}
              </div>

              <div className="col-md-12 mb-3">
                <button
                  disabled={isSubmitting}
                  className="btn bg-gradient-dark mt-3 d-block btn-lg mb-1"
                  type="submit"
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <div className="container-fluid py-3">
        <div className="d-flex align-items-center">
          <div className="me-2">
            <button
              className="btn bg-gradient-dark float-end d-flex btn-md mb-1 align-items-center h-52"
              onClick={() => setFilter({ type: 1 })}
              disabled={filter.type === 1}
            >
              Privacy Policy
            </button>
          </div>
          <div className="me-2">
            <button
              className="btn bg-gradient-dark float-end d-flex btn-md mb-1 align-items-center h-52"
              onClick={() => setFilter({ type: 2 })}
              disabled={filter.type === 2}
            >
              Terms & Conditions
            </button>
          </div>
        </div>
        {visible.loader && <Loader />} <FormikForm />
      </div>
    </>
  );
};

export default CMS;
