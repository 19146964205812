import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import logo from "../../assests/logo.png";
import { ForgotPasswordSchema } from "../../Helpers/Constants/Schemas";
import { ForgotPasswordFileds } from "../../Helpers/Constants/InitialValues";

import Loader from "../Common/Loader";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { Link } from "react-router-dom";
import { EndPoints } from "../../Helpers/Constants/EndPoints";

const ForgotPassword = () => {
  const [visible, setVisible] = useState(false);
  const [initialValues] = useState(ForgotPasswordFileds);

  const handleSubmit = async (params) => {
    try {
      setVisible(true);
      await Axios.post(API.AUTH.FORGOT_PASSWORD, params);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible(false);
    }
  };

  return (
    <>
      {visible && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <main className="main-content mt-0">
            <div className="page-header align-items-start min-vh-100">
              <span className="mask opacity-6"></span>
              <div className="container my-auto">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-12 mx-auto">
                    <div className="log-logo">
                      <img className="logo" src={logo} alt="" />
                      <h4>Field Tracking</h4>
                    </div>
                    <div className="card z-index-0 fadeIn3 fadeInBottom">
                      <div className="card-body">
                        <div className="btn-bck">
                          <h1>Forgot Password</h1>
                          <Link to={EndPoints.LOGIN}>
                            <i className="fas fa-arrow-left pe-1"></i> Back
                          </Link>
                        </div>

                        <Form
                          role="form"
                          className="text-start login-form py-0"
                        >
                          <div className="input-group input-group-outline my-3 ">
                            <label className="form-label ">Email</label>
                            <Field
                              className="form-control w-100"
                              type="text"
                              name="email"
                            />
                            {errors.email && touched.email ? (
                              <div className="error">{errors.email}</div>
                            ) : null}
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn bg-gradient-dark w-100 my-4 mb-2"
                            >
                              Submit
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
