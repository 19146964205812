import React, { useEffect, useState } from "react";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CreatedAt } from "../../Helpers/Universal/Function/common";
import { useLocation, useNavigate } from "react-router-dom";

const SubAdmin = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [visible, setVisible] = useState({ loader: false });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(
        API.SUB_ADMIN.DETAILS + "/" + state
      );
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    state ? getData() : navigate(EndPoints.SUB_ADMINS);
  }, []);

  return (
    <>
      {visible.loader && <Loader />}
      <div className="profile-view">
        <div className="inner-agent">
          <h4>Sub-admin Details</h4>
          <div className="row align-items-start mx-0 p-3">
            <ul className="col-8">
            {/* <li>
              <b>
                <i>Profile:</i>
              </b>
              <span>
                <img src={data.image} alt={data.image} />
              </span>
            </li> */}
            <li>
              <b>
                <i>Name:</i>
              </b>
              <span>{data.name}</span>
            </li>
            <li>
              <b>
                <i>Email:</i>
              </b>
              <span>{data.email}</span>
            </li>
            <li>
              <b>
                <i>Password:</i>
              </b>
              <span>{data.dcryptedPass}</span>
            </li>
            <li>
              <b>
                <i>Blocked:</i>
              </b>
              <span>{data.isBlocked ? "Blocked" : "Un-blocked"}</span>
            </li>
            <li>
              <b>
                <i>Date:</i>
              </b>
              <span>{CreatedAt(data.createdAt)}</span>
            </li>

            
          </ul>
          <div className="successfull bg-white p-4 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
              <table className="table table-bordered text-center">
                <thead className="bg-light text-dark">
                  <tr>
                    <th scope="col">Menu Name</th>
                    <th scope="col">All</th>
                    <th scope="col">View</th>
                    <th scope="col">Add</th>
                  </tr>
                </thead>
                <tbody>
                  {data.roleId?.permissions?.map((access) => (
                    <tr>
                      <td>{access.name}</td>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={access.all}
                        ></input>
                      </td>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={access.view}
                        ></input>
                      </td>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultChecked={access.add}
                        ></input>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {/* <div className="d-flex justify-content-end mt-5">
                <button
                  type="submit"
                  className="btn bg-gradient-dark float-end d-flex btn-md mb-1"
                >
                  Save
                </button>
              </div> */}
            </div>
          </div>
       
        </div>
      </div>
    </>
  );
};

export default SubAdmin;
