import React, { useState } from "react";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import Loader from "../Common/Loader";
import Swal from "sweetalert2";

const SuperLogout = () => {
  const [visible, setVisible] = useState({ loader: false });

  const handleSubmit = async () => {
    try {
      Swal.fire({
        title: `Logout all Users`,
        text: `Are you sure you want to Logout all Users?`,
        confirmButtonText: "Yes",
        showCancelButton: true,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            setVisible((prev) => ({ ...prev, loader: true }));
            await Axios.get(API.LOGOUT.LOGOUT_ALL);
          }
        })
        .finally(() => {
          setVisible((prev) => ({ ...prev, loader: false }));
        });
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  return (
    <>
      {visible.loader && <Loader />}
      <div className="profile-view">
        <div className="col-5 mx-auto col-xs-12">
          <div className="inner-agent">
            <h4>Logout all User's</h4>
            <button
              type="button"
              className="btn bg-gradient-dark mx-auto mt-5 d-block btn-lg mb-1"
              onClick={handleSubmit}
            >
              LOGOUT ALL
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperLogout;
