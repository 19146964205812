import React from "react";

const DataNotFound = () => {
  return (
    <div className="NotFound">
      <i className="fas fa-exclamation-triangle"></i> Data Not Found
    </div>
  );
};

export default DataNotFound;
