import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";

import { SendNotifySchema } from "../../Helpers/Constants/Schemas";
import { SendNotifyFields } from "../../Helpers/Constants/InitialValues";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import Paginate from "../Common/Paginate";
import { LIMIT, OFFSET } from "../../Helpers/Constants/Default";
import { useSelector } from "react-redux";
import Loader from "../Common/Loader";
import {
  CreatedAt,
  TruncateText,
} from "../../Helpers/Universal/Function/common";
import Swal from "sweetalert2";
import DataNotFound from "../Common/DataNotFound";
import { Fragment } from "react";

const Notification = () => {
  const paginate = useSelector((val) => val.paginate);
  const [initialValues] = useState(SendNotifyFields.required);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({ loader: false });
  const [filter, setFilter] = useState({
    limit: LIMIT,
    offset: paginate?.notify || OFFSET,
  });

  const handleSubmit = async (data) => {
    try {
      await Axios.post(API.NOTIFICATION.SEND_NOTIFY, data);
      getData();
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(
        API.NOTIFICATION.GET_NOTIFY,
        filter
      );
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const handleViewPopup = (data) => {
    Swal.fire({
      title: data.title,
      text: data.body,
      confirmButtonText: "Close",
      showCancelButton: false,
    });
  };

  useEffect(() => {
    getData();
  }, [filter]);

  return (
    <Fragment>
      {visible.loader && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={SendNotifySchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form className="page-from">
            <div className="inner-form">
              <div className="row">
                <div className="col-md-4 mb-3">
                  <label>Title</label>
                  <Field className="form-control" type="text" name="title" />
                  {errors.title && touched.title ? (
                    <div className="error">{errors.title}</div>
                  ) : null}
                </div>

                <div className="col-md-9 mb-3">
                  <label>Message</label>
                  <Field
                    className="form-control"
                    type="text"
                    component="textarea"
                    name="body"
                  />
                  {errors.body && touched.body ? (
                    <div className="error">{errors.body}</div>
                  ) : null}
                </div>

                <div className="col-md-12 mb-3">
                  <button
                    disabled={isSubmitting}
                    className="btn bg-gradient-dark mt-3 d-block btn-lg mb-1"
                    type="submit"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {data?.data?.length === 0 ? (
        <DataNotFound />
      ) : (
        <Fragment>
          <div className="overflow-table">
            <table className="page-table cebtertable">
              <thead>
                <tr>
                  <th scope="col" className="text-uppercase">
                    #
                  </th>
                  <th scope="col" className="text-uppercase">
                    Date
                  </th>
                  <th scope="col" className="text-uppercase">
                    Title
                  </th>
                  <th scope="col" className="text-uppercase msg-body">
                    Body
                  </th>
                  <th scope="col" className="text-uppercase">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.map((val, i) => (
                  <tr key={val._id}>
                    <td>{++i + filter.offset}</td>
                    <td>{CreatedAt(val.createdAt)}</td>
                    <td>{val.title}</td>
                    <td className="msg-body">{TruncateText(val.body, 50)}</td>
                    <td className="td-btn" onClick={() => handleViewPopup(val)}>
                      <span>view</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Paginate
            count={data?.count}
            filter={filter}
            setFilter={setFilter}
            sliceKey={"notify"}
            forcePage={paginate?.notify / 10}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Notification;
