import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../config/config";

const MapContainer = ({ lat, lng }) => {
  const mapStyles = {
    height: "590px",
    width: "100%",
  };

  const defaultCenter = { lat, lng };

  return (
    <LoadScript googleMapsApiKey={GOOGLE_API_KEY}>
      <GoogleMap mapContainerStyle={mapStyles} zoom={15} center={defaultCenter}>
        {<Marker position={defaultCenter} />}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
