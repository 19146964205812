import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accType: 0,
};

const accTypeSlice = createSlice({
  name: "accType",
  initialState: initialState,
  reducers: {
    setAccType(state, value) {
      state.accType = value.payload;
    },
  },
});

export const { setAccType } = accTypeSlice.actions;
export default accTypeSlice.reducer;