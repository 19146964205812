export const NA = "NA";

export const NO_FILES = "no files";

export const UPDATE = "Update";

export const CREATE = "Create";

export const LIMIT = 10;

export const OFFSET = 0;

export const LIMITATION = [10, 20, 30, 40, 50];

export const NOT_FOUND = "404 Not Found";

export const UPLOAD = {
  Transaction: "transaction",
  P2pAccount: "p2pAccount",
  Accounting: "accounting",
  CustomExpense: "customExpense",
};

export const USER_TYPE = {
  AGENT: 1,
  SUPERVISOR: 2,
  MANAGER: 3,
};

export const ADMIN_TYPE = {
  ADMIN: 1,
  SUB_ADMIN: 2,
};

export const VERIFICATION = {
  1: "Pending",
  2: "Accepted",
  3: "Rejected",
};

export const USER_IDS = {
  AGENT: "A",
  SUPERVISOR: "S",
  MANAGER: "M",
};

export const ATTENDANCE = {
  STATUS: {
    0: "Pending",
    1: "Successful",
    2: "Failed",
  },
};

export const BLOCK_STATUS = {
  BLOCKED: "Block",
  UN_BLOCKED: "Un-Block",
};

export const BLOCK_ACCESS_STATUS = {
  ENABLE: "Enable",
  DISABLE: "Disable",
};

export const CONFIG = {
  TYPE: {
    1: "Slot Duration",
    2: "Before Attendance Mark",
    3: "After Attendance Mark",
  },
  VALUE: {
    1: [30, 60, 90, 120],
    2: [5, 10, 15, 20],
    3: [5, 10, 15, 20],
  },
};
