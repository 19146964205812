import API from "../../Constants/EndPoints/Api";
import Axios from "../Axios/Axios";

export const UpdateParam = async (
  { required, optional },
  values,
  removeFields = []
) => {
  const output = {},
    keys = Object.keys({ ...required, ...optional });

  keys.map((key) =>
    values[key] === undefined ? null : (output[key] = values[key])
  );

  removeFields?.forEach((key) => delete output[key]);

  return output;
};

export const CreatedAt = (createdAt) => {
  // Create a new Date object using the fetched date string
  const createdAtDate = new Date(createdAt);

  // Format the date in the desired format (day-month-year)
  const formattedDateString = createdAtDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  return formattedDateString || null;
};

export const timestampToHM = (timestamp) => {
  // Create a date object from the timestamp
  const date = new Date(timestamp);

  // Get the hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the time as HH:MM
  const time = hours + ":" + minutes;

  // Return the time
  return time;
};

export const ReverseObj = (obj) =>
  Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));

export const FilesFormData = (event, path) => {
  const data = event.target.files;

  if (data.length === 0) return false;

  const formData = new FormData();

  for (let i = 0; i < data.length; i++) formData.append("files", data[i]);

  formData.set("path", path);

  return formData;
};

export const FilesUpload = async (files) => {
  try {
    const { status, data } = await Axios.post(API.UPLOAD_FILE, files);
    if (status) return data;
    else return [];
  } catch (err) {
    console.log("ERROR==>", err);
  }
};

export const TruncateText = (text, wordLimit) => {
  const words = text.split(' '); // Split text into words
  if (words.length <= wordLimit) {
    return text; // If the word count is within the limit, return the text as is
  }
  return words.slice(0, wordLimit).join(' ') + '...'; // Otherwise, truncate and add "..."
};