import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import logo from "../../assests/logo.png";
import { ResetPasswordSchema } from "../../Helpers/Constants/Schemas";
import { ResetPasswordFileds } from "../../Helpers/Constants/InitialValues";

import Loader from "../Common/Loader";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { useLocation, useNavigate } from "react-router-dom";
import { EndPoints } from "../../Helpers/Constants/EndPoints";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [visible, setVisible] = useState(false);
  const [initialValues] = useState(ResetPasswordFileds);
  const [pass, setPass] = useState({ password: false, confirmPassword: false });

  const queryParams = new URLSearchParams(location.search);
  const uuid = queryParams.get("token");

  const handleSubmit = async (params) => {
    try {
      setVisible(true);
      const { status } = await Axios.post(API.AUTH.RESET_PASSWORD, {
        uuid: uuid,
        password: params.password,
      });
      if (status) navigate(EndPoints.LOGIN);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (!uuid) navigate(EndPoints.LOGIN);
  }, []);

  return (
    <>
      {visible && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={ResetPasswordSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <main className="main-content mt-0 reset-password">
            <div className="page-header align-items-start min-vh-100">
              <span className="mask opacity-6"></span>
              <div className="container my-auto">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-12 mx-auto">
                    <div className="log-logo">
                      <img className="logo" src={logo} alt="" />
                      <h4>Field Tracking</h4>
                    </div>
                    <div className="card z-index-0 fadeIn3 fadeInBottom">
                      <div className="card-body">
                        <div className="btn-bck">
                          <h1 className="mb-4">Reset Password</h1>
                        </div>

                        <Form
                          role="form"
                          className="text-start login-form form-label pt-0"
                        >
                          <div className="input-group input-group-outline mb-3 ">
                            <label className="form-label1 d-block  mt-">
                              Password
                            </label>
                            <div className="position-relative w-100">
                              <Field
                                className="form-control w-100"
                                type={pass?.password ? "text" : "password"}
                                name="password"
                              />
                              <i
                                className={`fa fa-eye${
                                  pass?.password ? "" : "-slash"
                                } eye-pos`}
                                onClick={() =>
                                  setPass((prev) => ({
                                    ...prev,
                                    password: !prev?.password,
                                  }))
                                }
                              ></i>
                            </div>
                            {errors.password && touched.password ? (
                              <div className="error">{errors.password}</div>
                            ) : null}
                          </div>
                          <div className="input-group input-group-outline mb-3 ">
                            <label className="form-label1 d-block">
                              Confirm Password
                            </label>
                            <div className="position-relative w-100">
                              <Field
                                className="form-control w-100"
                                type={
                                  pass?.confirmPassword ? "text" : "password"
                                }
                                name="confirmPassword"
                              />
                              <i
                                className={`fa fa-eye${
                                  pass?.confirmPassword ? "" : "-slash"
                                } eye-pos`}
                                onClick={() =>
                                  setPass((prev) => ({
                                    ...prev,
                                    confirmPassword: !prev?.confirmPassword,
                                  }))
                                }
                              ></i>
                            </div>
                            {errors.confirmPassword &&
                            touched.confirmPassword ? (
                              <div className="error">
                                {errors.confirmPassword}
                              </div>
                            ) : null}
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn bg-gradient-dark w-100 my-4 mb-2"
                            >
                              Change Password
                            </button>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
