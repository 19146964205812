import React, { useState } from "react";
import { Formik, Form, Field } from "formik";

import Axios from "../../Helpers/Universal/Axios/Axios";
import API from "../../Helpers/Constants/EndPoints/Api";
import { ConfigSchema } from "../../Helpers/Constants/Schemas";
import { ConfigFields } from "../../Helpers/Constants/InitialValues";
import { CONFIG } from "../../Helpers/Constants/Default";

const ConfigModal = ({ modalData, setModalData, setVisible, setData }) => {
  const [initialValues] = useState(
    modalData?.data?.value
      ? { value: modalData?.data?.value }
      : ConfigFields.required
  );

  const hide = () => {
    setModalData((prev) => ({ ...prev, visible: false }));
  };

  const handleSubmit = async (values) => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status } = await Axios.patch(API.CONFIG.UPDATE, {
        configId: modalData?.data?._id,
        value: values.value,
      });

      if (status)
        setData((prev) =>
          prev?.map((slot) =>
            slot._id === modalData?.data?._id
              ? { ...slot, value: values.value }
              : slot
          )
        );
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
      hide();
    }
  };

  return (
    <div className="bg_viewpopup" style={{ zIndex: "999999" }}>
      <div className="successfullModal  successfull delete-modal bg-white p-4 pb-0 w-50 top-50 start-50 end-0 border border-secondary rounded text-center position-absolute">
        <div className="modal-header">
          <h5>{CONFIG.TYPE[modalData?.data?.type]}</h5>
          <label className="cross-btns" onClick={hide}>
            ×
          </label>
        </div>
        <div className="modal-body mt-0 p-0">
          <div>
            <p className="mt-3 mb-0"></p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={ConfigSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {({ isSubmitting, errors, touched }) => (
              <Form className="page-from">
                <div className="inner-form">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label>
                        Change your '{CONFIG.TYPE[modalData?.data?.type]}' by
                        select a value
                      </label>
                      <Field className="form-control" name="value" as="select">
                        <option value="">Click to Select</option>
                        {CONFIG.VALUE[modalData?.data?.type]?.map((val) => (
                          <option key={val} value={val}>
                            {val}
                          </option>
                        ))}
                      </Field>
                      {errors.value && touched.value ? (
                        <div className="error">{errors.value}</div>
                      ) : null}
                    </div>

                    <div className="col-md-12 mb-0 mt-3 text-center">
                      <button
                        disabled={isSubmitting}
                        className="btn bg-gradient-dark d-block btn-lg mb-1"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ConfigModal;
