import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  CreatedAt,
  timestampToHM,
} from "../../Helpers/Universal/Function/common";
import { NA, ATTENDANCE } from "../../Helpers/Constants/Default";
import PathLocator from "../Common/Map/PathLocator";
import DataNotFound from "../Common/DataNotFound";

const AttendanceSlot = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [visible, setVisible] = useState({
    loader: false,
    deleteConformation: { data: false, visible: false },
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.AGENT.ATTENDANCE_SLOT, {
        attendanceId: state,
      });
      if (status) {
        setCoordinates(
          data?.map(({ location }) => ({ lat: location[1], lng: location[0] }))
        );
        setData(data);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    state ? getData() : navigate(EndPoints.AGENT);
  }, []);

  return (
    <>
      <div className="profile-view">
        <div className="map-right">
          <PathLocator coordinates={coordinates} />
        </div>

        {visible.loader && <Loader />}
        <div className="container-fluid py-3">
          <div className="overflow-table">
            <table className="page-table">
              <thead>
                <tr>
                  <th scope="col" className="text-uppercase">
                    #
                  </th>
                  <th scope="col" className="text-uppercase">
                    Profile
                  </th>
                  <th scope="col" className="text-uppercase">
                    Marked Time
                  </th>
                  <th scope="col" className="text-uppercase">
                    Status
                  </th>
                  <th scope="col" className="text-uppercase">
                    Start Time
                  </th>
                  <th scope="col" className="text-uppercase">
                    End Time
                  </th>
                  <th scope="col" className="text-uppercase">
                    Date
                  </th>
                </tr>
              </thead>
              {data?.length === 0 ? (
                <DataNotFound />
              ) : (
                <tbody>
                  {data?.map((val, i) => (
                    <tr key={val._id}>
                      <td>{++i}</td>
                      <td>
                        {val.image ? (
                          <img
                            className="profile"
                            src={val.image}
                            alt={val.name}
                          />
                        ) : (
                          NA
                        )}
                      </td>
                      <td>{timestampToHM(val.markedTime)}</td>
                      <td>{ATTENDANCE.STATUS[val.status]}</td>
                      <td>{timestampToHM(val.startTime)}</td>
                      <td>{timestampToHM(val.endTime)}</td>
                      <td>{CreatedAt(val.createdAt)}</td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceSlot;
