import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assests/logo.png";
import { sidebar } from "../../Helpers/Constants/Sidebar";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { ADMIN_TYPE } from "../../Helpers/Constants/Default";
import { useSelector } from "react-redux";
// import { useSelector } from "react-redux";
// import { USER_TYPE } from "../../Helpers/Constants/Default";

const Sidebar = ({ setCollapse, collapse }) => {
  // const [dropdown, setDropdown] = useState(false);

  const { pathname } = useLocation();
  const { accType } = useSelector((val) => val);
  // const { profile } = useSelector((val) => val);

  // const checkAvalability = (path) => {
  //   if (profile?.role === USER_TYPE[0]) return { view: true };

  //   if (!path) return { view: true };

  //   return profile
  //     ? profile?.permissions?.find((ele) => `/${ele.field}` === path)
  //     : false;
  // };

  return (
    <>
      <div className="mobile_navbar">
        <div className="row align-items-center">
          <div className="col-md-6 col-sm-6 col-xs-6 text-start">
            <div className="sidenav-footer  w-100 bottom-0 ">
              <button
                className="sidebarback"
                onClick={() => setCollapse((prev) => !prev)}
              >
                <i className={`fa fa-angle-${collapse ? "right" : "left"}`}></i>
              </button>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-6 text-end">
            <div className="sidenav-header">
              <i
                className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
                aria-hidden="true"
              ></i>
              <Link to={EndPoints.DASHBOARD}>
                <div className="navbar-brand m-0" target="_blank">
                  <span className="font-weight-bold text-white d-flex justify-content-end">
                    <img className="logo" src={logo} alt="" />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <aside
        className={`sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark ps bg-white 
      ${collapse ? "sidebarcollapse" : "null"}`}
      >
        <div className="sidenav-header">
          <i
            className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
            aria-hidden="true"
          ></i>
          <Link to={EndPoints.DASHBOARD}>
            <li className="navbar-brand m-0" target="_blank">
              <span className="font-weight-bold text-white d-flex justify-content-center">
                <img className="logo" src={logo} alt="" />
              </span>
            </li>
          </Link>
        </div>

        <hr className="horizontal light mt-0 mb-3"></hr>

        <div className="collapse navbar-collapse w-auto">
          <ul className="navbar-nav">
            {sidebar?.map((val, i, data) => {
              if (
                accType?.accType === ADMIN_TYPE.SUB_ADMIN &&
                val.name === "Sub Admin"
              )
                return false;
              // const avaliable = checkAvalability(val.link?.[0]);

              // if (avaliable && (avaliable?.view || avaliable?.all))
              return (
                <div key={i}>
                  <Link
                    onClick={() => {
                      val.name === "Logout" && localStorage.clear();
                    }}
                    to={val.link?.[0]}
                    className={
                      val.link?.includes(pathname) ? "active d-flex" : "null"
                    }
                  >
                    <li
                      className="nav-item nav-link text-white"
                      // onClick={() =>
                      //   val.name === "Settings" ? setDropdown(!dropdown) : null
                      // }
                    >
                      <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                        <i className="material-icons opacity-10">{val.icon}</i>
                      </div>
                      <span className="nav-link-text ms-1">{val.name}</span>
                    </li>
                  </Link>
                </div>
              );
            })}
          </ul>
        </div>

        <div className="sidenav-footer  w-100 bottom-0 mt-2">
          <button
            className="sidebarback"
            onClick={() => setCollapse((prev) => !prev)}
          >
            <i className={`fa fa-angle-${collapse ? "right" : "left"}`}></i>
          </button>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
