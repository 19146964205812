import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { SubAdminSchema } from "../../Helpers/Constants/Schemas";
import { SubAdminFields } from "../../Helpers/Constants/InitialValues";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CREATE, UPDATE } from "../../Helpers/Constants/Default";
import { UpdateParam } from "../../Helpers/Universal/Function/common";

const SubAdmin = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [isUpdate] = useState(state ? true : false);
  const [initialValues] = useState(isUpdate ? state : SubAdminFields.required);

  const handleSubmit = async (data) => {
    try {
      const { status } = await Axios.post(API.SUB_ADMIN.CREATE, data);
      if (status) navigate(EndPoints.SUB_ADMINS);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleUpdateDocument = async (data) => {
    try {
      const params = await UpdateParam(SubAdminFields, data);
      const { status } = await Axios.patch(API.SUB_ADMIN.UPDATE, {
        subAdminId: initialValues._id,
        ...params,
      });

      if (status) navigate(EndPoints.SUB_ADMINS);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SubAdminSchema}
      onSubmit={(values, { resetForm }) => {
        isUpdate ? handleUpdateDocument(values) : handleSubmit(values);
        // resetForm();
      }}
    >
      {({ isSubmitting, errors, touched }) => (
        <Form className="page-from">
          <div className="inner-form">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Name</label>
                <Field className="form-control" type="text" name="name" />
                {errors.name && touched.name ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Email</label>
                <Field className="form-control" type="email" name="email" />
                {errors.email && touched.email ? (
                  <div className="error">{errors.email}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Password</label>
                <Field className="form-control" type="text" name="password" />
                {errors.password && touched.password ? (
                  <div className="error">{errors.password}</div>
                ) : null}
              </div>

              <div className="col-md-12 mb-3">
                <button
                  disabled={isSubmitting}
                  className="btn bg-gradient-dark mt-3 d-block btn-lg mb-1"
                  type="submit"
                >
                  {isUpdate ? UPDATE : CREATE}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SubAdmin;
